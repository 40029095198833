import React, { Fragment } from 'react'
import { Copy, Hero, SectionFull, Sidebar } from '@website2018/da-dobsonville'
import { Helmet } from "react-helmet-async";
import { decode } from 'he';
import Posts from '../Newsroom/Posts'
import Asides from '../Asides/Asides'
import Layout from '../Layout/Base'

const CampaignTemplate = props => {
  const { campaign: p } = props

  return [
    <Helmet
      defaultTitle={decode(p.seo.title !== '' ? p.seo.title : p.title.rendered)}
      meta={[
        {
          name: 'description',
          content: p.seo.metadesc !== '' ? p.seo.metadesc : p.excerpt.rendered
        },
        {
          name: 'og:title',
          content: p.seo['opengraph-title'] !== '' ? p.seo['opengraph-title'] : p.title.rendered
        },
        {
          name: 'og:description',
          content:
            p.seo['opengraph-description'] !== ''
              ? p.seo['opengraph-description']
              : p.excerpt.rendered
        },
        { name: 'og:url', content: `` },
        { name: 'og:site_name', content: `Democratic Alliance` },
        { name: 'og:type', content: `business` },
        { name: 'og:image', content: p.seo['opengraph-image'] || null },
        { name: 'fb:app_id', content: '1262049647255981' },
        { name: 'twitter:card', content: '”summary_large_image”' },
        { name: 'twitter:site', content: '@our_da' },
        {
          name: 'twitter:title',
          content: p.seo['twitter-title'] !== '' ? p.seo['twitter-title'] : p.title.rendered
        },
        {
          name: 'twitter:description',
          content:
            p.seo['twitter-description'] !== '' ? p.seo['twitter-description”'] : p.excerpt.rendered
        },
        {
          name: 'twitter:image:src',
          content: p.seo['twitter-image'] !== '' ? p.seo['twitter-image'] : null
        }
      ]}
      htmlAttributes={{ lang: 'en' }}
    />,
    <Hero
      imageUrl={p.hero && p.hero.background_image ? p.hero.background_image.url : null}
      title={p.hero && p.hero.headline ? p.hero.headline : p.title.rendered}
    />,
    <Fragment>
      {p.content.rendered && (
        <SectionFull mt={'none'}>
          <div className={'col-lg-7'}>
            <article className="entry">
              <Copy intro html={p.content.rendered} />
              {p.acf.layout_content && p.acf.layout_content.length ? (
                <Layout layout={p.acf.layout_content} />
              ) : null}
            </article>
          </div>
          <Sidebar>{p.asides && <Asides asides={p.asides} />}</Sidebar>
        </SectionFull>
      )}
    </Fragment>,
    <Fragment>
      {p.acf.layout && p.acf.layout.length ? <Layout layout={p.acf.layout} /> : null}
    </Fragment>,
    p.posts && <Posts posts={p.posts} title={'Related News'} />
  ]
}

export default CampaignTemplate
